import React, { useState, useEffect } from 'react';
import priceConfig from '../constants/priceConfig';

const AssetSelectionComponent = ({ onAssetSelect }) => {
  const [selectedAsset, setSelectedAsset] = useState('regular'); // Set default value to 'regular'

  useEffect(() => {
    onAssetSelect(selectedAsset);
  }, [selectedAsset, onAssetSelect]);

  const handleChange = (event) => {
    setSelectedAsset(event.target.value);
  };

  return (
    <form>
      <label>
        <input
          type="radio"
          id="vip"
          name="asset"
          value="vip"
          checked={selectedAsset === 'vip'}
          onChange={handleChange}
        />
        VIP - {priceConfig.vip_discount}% discount
        <br/>
      </label>

      <label>
        <input
          type="radio"
          id="novel"
          name="asset"
          value="novel"
          checked={selectedAsset === 'novel'}
          onChange={handleChange}
        />
        Novel Assets - {priceConfig.novel_discount}% discount
        <br/>     
      </label>

      <label>
        <input
          type="radio"
          id="yuga"
          name="asset"
          value="yuga"
          checked={selectedAsset === 'yuga'}
          onChange={handleChange}
        />
        Yuga Assets - {priceConfig.yuga_discount}% discount
        <br/> 
      </label>

      <label>
        <input
          type="radio"
          id="regular"
          name="asset"
          value="regular"
          checked={selectedAsset === 'regular'}
          onChange={handleChange}
        />
        Regular
      </label>
    </form>
  );
};

export default AssetSelectionComponent;
