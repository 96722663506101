import React, { createContext, useState } from 'react';

// Create the context
export const AvailabilityContext = createContext();

// Create a Provider Component
export const AvailabilityProvider = ({ children }) => {
  const [dayTicketAvailable, setDayTicketAvailable] = useState(false);
  const [premiumTicketAvailable, setPremiumTicketAvailable] = useState(false);
  const [luxuryTicketAvailable, setLuxuryTicketAvailable] = useState(false);

  const value = {
    dayTicketAvailable,
    setDayTicketAvailable,
    premiumTicketAvailable,
    setPremiumTicketAvailable,
    luxuryTicketAvailable,
    setLuxuryTicketAvailable,
  };

  return (
    <AvailabilityContext.Provider value={value}>
      {children}
    </AvailabilityContext.Provider>
  );
};
