import React, { useState } from 'react';

const ContactComponent = ({ onContactChange, onEmailChange, isEmailValid }) => {
  const [contact, setContact] = useState('');
  const [email, setEmail] = useState('');

  const handleContactChange = (event) => {
    const contactValue = event.target.value;
    setContact(contactValue);
    if (onContactChange) {
      onContactChange(contactValue);
    }
  };

  const handleEmailChange = (event) => {
    const emailValue = event.target.value;
    setEmail(emailValue);
    if (onEmailChange){
      onEmailChange(emailValue);
    }
  };

  return (
    <div className="contact-container">
      <input
        type="text"
        value={contact}
        onChange={handleContactChange}
        placeholder="Enter your Discord/Twitter handle"
        className="contact-input"
      />
      <input
        type="email"
        value={email}
        onChange={handleEmailChange}
        placeholder="Enter your Email Address*"
        className={`email-input ${isEmailValid ? '' : 'invalid'}`}
      />
      {!isEmailValid && <p className="error-message">Please enter a valid email address.</p>}
    </div>
  );
};

export default ContactComponent;
