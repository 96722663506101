import React, { useState, useContext } from 'react';
import { AvailabilityContext } from '../context/AvailabilityContext';
import priceConfig from '../constants/priceConfig';

const RoomSelectionComponent = ({ onRoomSelect, selectedDate }) => {
  const [selectedRoom, setSelectedRoom] = useState('');
  
  const {
    dayTicketAvailable,
    premiumTicketAvailable,
    luxuryTicketAvailable
  } = useContext(AvailabilityContext);

  const handleChange = (event) => {
    setSelectedRoom(event.target.value);
    onRoomSelect(event.target.value);
  };

  // Function to determine if any dates are selected
  const areDatesSelected = () => {
    return selectedDate && selectedDate.length > 0;
  };

  return (
    <div>
      <form>
        <label>
          <input
            type="radio"
            value="luxury"
            checked={selectedRoom === 'luxury'}
            onChange={handleChange}
            disabled={!luxuryTicketAvailable}
          />
          {/* Show "Fully booked" only if dates are selected and the room is not available */}
          <span className="disabled-text">Luxury Premium Night - €{priceConfig.luxury}{!luxuryTicketAvailable && areDatesSelected() && " - Fully booked"}</span>
        </label>
        <br />
        <div style={{ marginTop: '5px' }}></div>
        <label>
          <input
            type="radio"
            value="premium"
            checked={selectedRoom === 'premium'}
            onChange={handleChange}
            disabled={!premiumTicketAvailable}
          />
          {/* Show "Fully booked" only if dates are selected and the room is not available */}
          <span className="disabled-text">Premium Night - €{priceConfig.premium}{!premiumTicketAvailable && areDatesSelected() && " - Fully booked"} </span>
        </label>
        <br />
        <div style={{ marginTop: '5px' }}></div>
        <label>
          <input
            type="radio"
            value="day"
            checked={selectedRoom === 'day'}
            onChange={handleChange}
            disabled={!dayTicketAvailable}
          />
          {/* Show "Fully booked" only if dates are selected and the room is not available */}
          <span className="disabled-text">Day Ticket - €{priceConfig.day}{!dayTicketAvailable && areDatesSelected() && " - Fully booked"} </span>
        </label>
      </form>
    </div>
  );
};

export default RoomSelectionComponent;
