const colorConfig = {
    default: {
      text: 'black',
      background: 'white'
    },
    selected_edge: {
      text: 'white',
      background: 'black'
    },
    selected_middle: {
        text: 'white',
        background: '#212121'
    },
    disabled: {
      text: '#2b2b2b',
      background: 'transparent'
    },
    hover: {
      text: 'grey',
      background: 'black'
    },
    dayAllAvailable: {
      text: 'white',
      background: '#4B713E'
    },
    daySomeAvailable: {
      text: 'white',
      background: '#716C3E'
    },
    dayAllBooked: {
      text: 'white',
      background: '#6B2525'
    },
  };
  
  export default colorConfig;
  