import React, { useState, useRef } from 'react';
import CalendarComponent from './components/CalendarComponent';
import AssetSelectionComponent from './components/AssetSelectionComponent';
import PlusOneComponent from './components/PlusOneComponent';
import RoomSelectionComponent from './components/RoomSelectionComponent';
import ContactComponent from './components/ContactComponent';
import TotalPriceComponent from './components/TotalPriceComponent';
import { AvailabilityProvider } from './context/AvailabilityContext';
import axios from 'axios';
import {BaseProvider, LightTheme} from 'baseui';
import { Provider as StyletronProvider } from "styletron-react";
import { Client as Styletron } from "styletron-engine-atomic";
import { CryptoProvider } from './context/CryptoContext';

import './App.css';
const engine = new Styletron();

const App = () => {
  const totalPriceRef = useRef();
  const [selectedDates, setSelectedDates] = useState([]);
  const [selectedAsset, setSelectedAsset] = useState('');
  const [plusOne, setPlusOne] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState('');
  const [contactInfo, setContactInfo] = useState('');
  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [reservationConfirmed, setReservationConfirmed] = useState(false);
  const [totalPrice, setTotalPrice] = useState('0');
  const [totalPriceETH, setTotalPriceETH] = useState('0');
  const [totalPriceUSDC, setTotalPriceUSDC] = useState('0');
  const [convertionRateToETH, setConvertionRateToETH] = useState('0');
  const [convertionRateToUSDC, setConvertionRateToUSDC] = useState('0');
  const [bookingReference, setBookingReference] = useState(null);
  const [validationErrors, setValidationErrors] = useState([]);
  const [errorMessage, setErrorMessage] = useState({ title: '', body: '' });

  // Handle date change from the CalendarComponent
  const handleDateChange = (dates) => {
    //validateState(selectedDates, email, selectedRoom);
    const forceUpdate = dates.length >= 2 && dates[0].toISOString() !== dates[1].toISOString();
    setSelectedDates(dates);
    if(forceUpdate){
      validateState(dates, email, selectedRoom);
    }
  };

  // Handle asset selection change from the AssetSelectionComponent
  const handleAssetSelect = (asset) => {
    setSelectedAsset(asset);
  };

  // Handle plus one change from the PlusOneComponent
  const handleTogglePlusOne = (hasPlusOne) => {
    setPlusOne(hasPlusOne);
    validateState(selectedDates, email, selectedRoom);
  };

  // Handle room selection change from the RoomSelectionComponent
  const handleRoomSelect = (room) => {
    setSelectedRoom(room);
    validateState(selectedDates, email, selectedRoom);
  };

  // Handle contact info change from the ContactComponent
  const handleContactInfo = (info) => {
    setContactInfo(info);
    validateState(selectedDates, email, selectedRoom);
  };

  const handleEmailChange = (email) => {
    setEmail(email);
    validateState(selectedDates, email, selectedRoom);
  };

  const handleTotalPriceChange = (newTotalPrice, newTotalPriceETH, newTotalPriceUSDC, ethPrice, usdcPrice) => {
    setTotalPrice(newTotalPrice);
    setTotalPriceETH(newTotalPriceETH);
    setTotalPriceUSDC(newTotalPriceUSDC);
    setConvertionRateToETH(ethPrice);
    setConvertionRateToUSDC(usdcPrice);
  };

  // Submit reservation to the backend
  const handleSubmitReservation = () => {
    if (!validateState(selectedDates, email, selectedRoom)){
      return;
    }

    const startDate = selectedDates[0]; // Format these dates as per your server's expectation
    const endDate = selectedDates[selectedDates.length - 1];
  
    const totalPayment = totalPrice;
    const totalPaymentETH = totalPriceETH;
    const totalPaymentUSDC = totalPriceUSDC;

    const reservationDetails = {
      startDate: startDate,
      endDate: endDate,
      bookingType: selectedRoom,
      assetType: selectedAsset,
      plusOne: plusOne,
      email: email,
      totalPayment: {
        fiat: totalPayment,
        eth: totalPaymentETH,
        usdc: totalPaymentUSDC,
        usdcRate: convertionRateToUSDC,
        ethRate: convertionRateToETH
      },
      contact: contactInfo
    };
    axios.post(process.env.REACT_APP_WEB_SERVER+'/addBooking', reservationDetails)
      .then((response) => {
        setReservationConfirmed(true);
        setBookingReference(response.data['_id']);

        totalPriceRef.current.setPrice(
          response.data.totalPayment.fiat, 
          response.data.totalPayment.eth, 
          response.data.totalPayment.usdc
        );
        setConvertionRateToETH(response.data.totalPayment.ethRate);
        setConvertionRateToUSDC(response.data.totalPayment.usdcRate);
      })
      .catch((error) => {
        console.error('Reservation failed:', error.message);
        const responseMessage = (error.response && error.response.data && error.response.data.message) || error.message;
        const errorMessage = `${responseMessage}\n\n Please try again later.`;
        setErrorMessage({
          title: 'Failed to make a reservation!',
          body: errorMessage
        });
      });
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email.toLowerCase());
  };

  const validateState = (selectedDates, email, selectedRoomType) => {
    const minSelectedDates = 1;
    const errorMessages = [];

    const validDates = selectedDates.length >= minSelectedDates;
    const validEmail = validateEmail(email);
    const validRoomType = selectedRoomType !== '';

    setIsEmailValid(validEmail);

    if (!validDates) {
      errorMessages.push("Select at least 2 days for your stay.");
    }
  
    if (!validRoomType) {
      errorMessages.push("Pick a room type.");
    }

    setValidationErrors(errorMessages);

    return errorMessages.length === 0;
  };

  return (
    <StyletronProvider value={engine}>
      <BaseProvider theme={LightTheme}>
        <CryptoProvider>
          <AvailabilityProvider>
            <div className="wrapper">
              {/* Logo */}
              <div className="logo-top">
                <img className="embassy-logo" src="https://uploads-ssl.webflow.com/642ed5577f370f43c0fabd66/65afb5a1d290774261f83681_Embassy.svg" alt="Logo" style={{ width: '200px', height: 'auto' }} />
              </div>
              {/* Main content */}
              <div className="main-content">
                {!reservationConfirmed ? (
                  <div className="col-content">
                    <div><h1 className="hero-h1">Reserve your spot at The Embassy</h1></div>
                    <div className="sec-content">
                    <div className="calendar-wrapper">
                      <CalendarComponent onDateChange={handleDateChange} />
                    </div>
                    <div className="zero-top">
                      <div className="element"><h3>Ticket type*</h3><AssetSelectionComponent onAssetSelect={handleAssetSelect} /></div>
                      <div className="element"><h3>Are you bringing +1?</h3><PlusOneComponent onTogglePlusOne={handleTogglePlusOne} /></div>
                      <div className="element"><h3>Select your room*</h3><RoomSelectionComponent onRoomSelect={handleRoomSelect} selectedDate={selectedDates} /></div>
                      <div className=""><h3>Contact details</h3><ContactComponent onContactChange={handleContactInfo} onEmailChange={handleEmailChange} isEmailValid={isEmailValid}/></div>
                    </div>
                  </div>
                  </div>
                ) : (
                  <div className="center">
                    <h2 className="hero-h2">Reservation Confirmed!</h2>
                    <p>Your booking reference number is: {bookingReference}</p>
                    <p>We will get in touch with you to confirm the reservation.</p>
                  </div>
                )}
                <div className="box">
                  <TotalPriceComponent 
                    ref={totalPriceRef}
                    selectedDates={selectedDates}
                    selectedAsset={selectedAsset}
                    plusOne={plusOne}
                    selectedRoom={selectedRoom}
                    onTotalPriceChange={handleTotalPriceChange}
                  />
                  {!reservationConfirmed ? (
                    <button
                    onClick={handleSubmitReservation}
                    className="confirmButton"
                  >
                    Confirm Reservation
                  </button>
                  ): ( <div></div> )}
                    {/* Render validation error messages */}
                    {validationErrors.length > 0 && (
                    <div>
                      {validationErrors.map((error, index) => (
                        <p key={index} className="error-message-">{error}</p>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              <div className="logo-bot">
                <p className="gecko">ETH prices calculated at time of reservation, <a href="https://www.coingecko.com/">Data provided by CoinGecko</a></p>
              <img className="novel-logo" src="https://uploads-ssl.webflow.com/642ed5577f370f43c0fabd66/656c2a64440c9848c5297eeb_Novel%20Logo%20(1).svg" alt="Logo" style={{ width: '50px', height: 'auto' }} />
            </div>
            </div>
            {errorMessage.title && (
              <div className="error-overlay">
                <div className="error-popup">
                  <h3 style={{ textAlign: 'center', marginTop: '0', paddingTop: '0px' }}>{errorMessage.title}</h3>
                  <div style={{ textAlign: 'center', padding: '10px', paddingTop: '20px' }}>
                    {errorMessage.body.split('\n').map((line, index) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                  </div>
                  <button onClick={() => setErrorMessage({ title: '', body: '' })} style={{ marginTop: '20 px' }}>Close</button>
                </div>
              </div>
            )}
          </AvailabilityProvider>
        </CryptoProvider>
      </BaseProvider>
    </StyletronProvider>
  );
};

export default App;
