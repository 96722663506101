import React, { useState } from 'react';
import priceConfig from '../constants/priceConfig';

const PlusOneComponent = ({ onTogglePlusOne }) => {
  const [plusOne, setPlusOne] = useState(false);

  const handleChange = () => {
    setPlusOne(!plusOne);
    onTogglePlusOne(!plusOne);
  };

  return (
    <label>
      <input
        type="checkbox"
        checked={plusOne}
        onChange={handleChange}
      />
      Plus One - €{priceConfig.plusOne}
    </label>
  );
};

export default PlusOneComponent;
