// CryptoContext.js
import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

export const CryptoContext = createContext();

export const CryptoProvider = ({ children }) => {
  const [cryptoPrices, setCryptoPrices] = useState({ eth: 0, usdc: 0 });

  const getCryptoPrices = async () => {
    const url = "https://api.coingecko.com/api/v3/simple/price";
    const params = {
      ids: 'ethereum,usd-coin',
      vs_currencies: 'eur',
      x_cg_demo_api_key: process.env.REACT_APP_COINGECKO_APIKEY
    };
    
    try {
      const response = await axios.get(url, { params });
      const prices = response.data;
      setCryptoPrices({
        eth: prices.ethereum.eur,
        usdc: prices['usd-coin'].eur
      });
    } catch (error) {
      console.error("Error fetching crypto prices:", error);
    }
  };

  // Fetch prices every 10 minutes (600000 milliseconds)
  useEffect(() => {
    getCryptoPrices();
    const interval = setInterval(getCryptoPrices, 600000);
    return () => clearInterval(interval);
  }, []);

  return (
    <CryptoContext.Provider value={{ cryptoPrices }}>
      {children}
    </CryptoContext.Provider>
  );
};
