import React, { useContext, useEffect, useState, useCallback, useImperativeHandle, forwardRef } from 'react';
import priceConfig from '../constants/priceConfig';
import { differenceInCalendarDays } from 'date-fns';
import { CryptoContext } from '../context/CryptoContext';
import ethIcon from 'cryptocurrency-icons/svg/color/eth.svg';
import usdcIcon from 'cryptocurrency-icons/svg/color/usdc.svg';

const TotalPriceComponent = forwardRef(({ selectedDates, selectedAsset, plusOne, selectedRoom, onTotalPriceChange }, ref) => {
  const { cryptoPrices } = useContext(CryptoContext);

  const [totalPrice, setTotalPrice] = useState(0);
  const [totalPriceETH, setTotalPriceETH] = useState(0);
  const [totalPriceUSDC, setTotalPriceUSDC] = useState(0);

  const formatPrice = (price) => {
    return new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(price);
  };

  const getAssetDiscount = (assetType, roomType) => {
    switch (roomType.toLowerCase()) {
      case 'day':
        return 0;
      default:
        return priceConfig[`${assetType.toLowerCase()}_discount`] || 0;
    }
  };

  const calculateTotalPrice = useCallback(() => {
    if (!selectedDates || selectedDates.length < 2) return 0;

    const startDate = new Date(selectedDates[0]);
    const endDate = new Date(selectedDates[selectedDates.length - 1]);
    const numberOfDays = differenceInCalendarDays(endDate, startDate) + 1;

    const roomPrice = priceConfig[selectedRoom];
    const assetDiscount = getAssetDiscount(selectedAsset, selectedRoom);
    const plusOnePrice = plusOne ? priceConfig.plusOne * numberOfDays : 0;

    let totalPrice = roomPrice * (1 - assetDiscount / 100) * numberOfDays + plusOnePrice;
    if (!totalPrice) totalPrice = 0;

    return totalPrice;
  }, [selectedDates, selectedAsset, plusOne, selectedRoom]);

  useEffect(() => {
    // Recalculate the total price based on the current state
    const newTotalPrice = calculateTotalPrice();
    setTotalPrice(newTotalPrice);
  
    let newTotalPriceETH = cryptoPrices.eth > 0 ? newTotalPrice / cryptoPrices.eth : 0;
    let newTotalPriceUSDC = cryptoPrices.usdc > 0 ? newTotalPrice / cryptoPrices.usdc : 0;
  
    // Update the ETH and USDC prices based on the new total price
    setTotalPriceETH(newTotalPriceETH);
    setTotalPriceUSDC(newTotalPriceUSDC);
  
    // Trigger the callback with the updated values
    if (onTotalPriceChange) {
      onTotalPriceChange(newTotalPrice, newTotalPriceETH, newTotalPriceUSDC, cryptoPrices.eth, cryptoPrices.usdc);
    }
  }, [selectedDates, selectedAsset, plusOne, selectedRoom, cryptoPrices, calculateTotalPrice, setTotalPrice, onTotalPriceChange]);

  useImperativeHandle(ref, () => ({
    setPrice: (newTotalPrice, newTotalPriceETH, newTotalPriceUSDC) => {
      setTotalPrice(newTotalPrice);
      setTotalPriceETH(newTotalPriceETH);
      setTotalPriceUSDC(newTotalPriceUSDC);
    }
  }));

  const getDescription = () => {
    if (!selectedDates || selectedDates.length < 2) return '';

    const startDate = new Date(selectedDates[0]);
    const endDate = new Date(selectedDates[selectedDates.length - 1]);
    const numberOfDays = differenceInCalendarDays(endDate, startDate) + 1;

    const assetDiscount = getAssetDiscount(selectedAsset, selectedRoom);
    const plusOneCost = plusOne ? ` + €${priceConfig.plusOne} (plus one) per day` : '';

    let description = `Total for ${numberOfDays} `;
    if (selectedRoom === 'day') {
      description += `days. Discount does not apply to day tickets. `;
    } else {
      description += `nights in a ${selectedRoom} room with ${assetDiscount}% discount${plusOneCost}.`;
    }

    return description;
  };

  return (
    <div className="zero-top center">
      <h2>Total: €{formatPrice(totalPrice)}</h2>
      <div className="price-box">
      {totalPriceETH > 0 && 
        <p style={{ marginRight: '5px', marginLeft: '5px'}}>
          <img src={ethIcon} alt="ETH" style={{ height: '1.5em', verticalAlign: 'middle', marginRight: '5px', marginTop: '-6px' }}/> 
          {formatPrice(totalPriceETH)} at €{formatPrice(cryptoPrices.eth)} 
        </p>
      }
      {totalPriceUSDC > 0 &&
        <p style={{ marginRight: '5px', marginLeft: '5px'}}>
          <img src={usdcIcon} alt="USDC" style={{ height: '1.5em', verticalAlign: 'middle', marginRight: '5px', marginTop: '-6px' }}/> 
          {formatPrice(totalPriceUSDC)} at €{formatPrice(cryptoPrices.usdc)}
        </p>
      }
      </div>
      <p className="no-margin">{getDescription()}</p> 
    </div>
  );
});

export default TotalPriceComponent;
