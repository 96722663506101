import React, { useState, useEffect, useContext } from 'react';
import { format, addDays, isWithinInterval } from 'date-fns';
import colorConfig from '../constants/colorConfig';
import axios from 'axios';
import {ORIENTATION, StatefulCalendar} from 'baseui/datepicker';
import { StyledDay } from 'baseui/datepicker';
import { AvailabilityContext } from '../context/AvailabilityContext';
import { ThemeProvider, createTheme, /*lightThemePrimitives,*/ darkThemePrimitives } from 'baseui';

const CalendarComponent = ({onDateChange}) => {
  const [availabilityData, setAvailabilityData] = useState([]);
  const [selectedRange, setSelectedRange] = useState({ startDate: null, endDate: null });
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);
  const [serverCallSuccessful, setServerCallSuccessful] = useState(true);

  const minDate = new Date(2024, 2, 24);
  const maxDate = new Date(2024, 3, 6);

  const overrides = {
    calendarBackground: "transparent",
    calendarForeground: "white",
    calendarForegroundDisabled: colorConfig.textColorDisabled,
    calendarHeaderBackground: "transparent",
    calendarHeaderForegroundDisabled: "white",
    calendarDayBackgroundSelectedHighlighted: "black",
    calendarDayBackgroundSelected: "blue",
    calendarDayForegroundPseudoSelectedHighlighted: "black",
    calendarDayBackgroundPseudoSelectedHighlighted: "white",
  };

  const calendarStyles = isMobileView ? { maxWidth: '300px', margin: 'auto' } : {};

  useEffect(() => {
    axios.get(process.env.REACT_APP_WEB_SERVER+'/getBookingDays')
      .then(response => {
        setAvailabilityData(response.data);
        setServerCallSuccessful(true);
      })
      .catch(error => {
        console.error('Error fetching availability data:', error);
        setServerCallSuccessful(false);
      });
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 768);
    };
  
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const CustomTheme = createTheme(darkThemePrimitives, {
    colors: {
      ...darkThemePrimitives.colors,
      ...overrides, 
    },
  });

  const {
    setDayTicketAvailable,
    setPremiumTicketAvailable,
    setLuxuryTicketAvailable
  } = useContext(AvailabilityContext);

  // Update the availability flags based on the selected date range
  const updateAvailabilityFlags = (start, end) => {
    let dayAvailable = true, premiumAvailable = true, luxuryAvailable = true;

    let currentDate = new Date(Date.UTC(start.getFullYear(), start.getMonth(), start.getDate()));
    const endDate = new Date(Date.UTC(end.getFullYear(), end.getMonth(), end.getDate()));

    while (currentDate <= endDate) {
      const formattedDate = format(currentDate, 'yyyy-MM-dd');
      const dayInfo = availabilityData.find(data => data.date === formattedDate);

      if (dayInfo && dayInfo.dayAvailability <= 0) dayAvailable = false;
      if (dayInfo && dayInfo.premiumAvailability <= 0) premiumAvailable = false;
      if (dayInfo && dayInfo.luxuryAvailability <= 0) luxuryAvailable = false;
      currentDate = addDays(currentDate, 1);
    }

    setDayTicketAvailable(dayAvailable);
    setPremiumTicketAvailable(premiumAvailable);
    setLuxuryTicketAvailable(luxuryAvailable);
  };

  const handleDateRangeChange = (range) => {
    let startDate, endDate;
    const len = range.date.length;

    if (len === 1) {
      startDate = new Date(range.date[0]);
      endDate = new Date(range.date[0]);
    } else if (len >= 2) {
      startDate = new Date(range.date[0]);
      endDate = new Date(range.date[len - 1]);
    }

    // Set both dates to noon to avoid DST issues
    startDate.setHours(12, 0, 0, 0);
    endDate.setHours(12, 0, 0, 0);

    updateAvailabilityFlags(startDate, endDate);
    setSelectedRange({ startDate, endDate });

    if (onDateChange) {
      onDateChange([startDate, endDate]);
    }
  };

  const getDayStateColor = (day) => {
    const { $date, $disabled, $selected, ...otherProps } = day;
    const formattedDate = formatUTCDate($date);
    let backgroundColor, textColor;

    const isWithinSelectedRange = selectedRange.startDate && selectedRange.endDate &&
      isWithinInterval(new Date(formattedDate), {
        start: selectedRange.startDate,
        end: selectedRange.endDate
      });

    if (isWithinSelectedRange && otherProps.tabIndex) {
      ({ backgroundColor, textColor } = getColorConfig(colorConfig.selected_middle));
    } else if ($disabled) {
      ({ backgroundColor, textColor } = getColorConfig(colorConfig.disabled));
    } else if ($selected) {
      ({ backgroundColor, textColor } = getColorConfig(colorConfig.selected_edge));
    } else if (!otherProps.tabIndex) {
      ({ backgroundColor, textColor } = getColorConfig(colorConfig.hover));
    } else {
      ({ backgroundColor, textColor } = getAvailabilityColors(formattedDate, day));
    }
  
    return { backgroundColor, textColor };
  };
  
  const formatUTCDate = (date) => {
    const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    return utcDate.toISOString().split('T')[0];
  };
  
  const getColorConfig = (colorType) => ({
    backgroundColor: colorType.background,
    textColor: colorType.text
  });
  
  const getAvailabilityColors = (formattedDate, day) => {
    if (!serverCallSuccessful) {
      // If the server call failed, treat all days as fully booked
      return getColorConfig(colorConfig.dayAllBooked);
    }

    const dayInfo = availabilityData.find(data => data.date === formattedDate);
    if (!dayInfo && day >= minDate && day <= maxDate) {
      return getColorConfig(colorConfig.dayAllAvailable);
    } else if (dayInfo) {
      if (isAllAvailable(dayInfo)) {
        return getColorConfig(colorConfig.dayAllAvailable);
      } else if (isSomeAvailable(dayInfo)) {
        return getColorConfig(colorConfig.daySomeAvailable);
      } else {
        return getColorConfig(colorConfig.dayAllBooked);
      }
    }
    return getColorConfig(colorConfig.dayAllAvailable);
  };
  
  const isAllAvailable = (dayInfo) => (
    dayInfo.premiumAvailability > 0 &&
    dayInfo.luxuryAvailability > 0 &&
    dayInfo.dayAvailability > 0
  );
  
  const isSomeAvailable = (dayInfo) => (
    dayInfo.premiumAvailability > 0 ||
    dayInfo.luxuryAvailability > 0 ||
    dayInfo.dayAvailability > 0
  );

  const dayRenderer = (day) => {
    const { $date, ...otherProps } = day;
  
    const {backgroundColor, textColor} = getDayStateColor(day);
    const customStyle = {
      backgroundColor: backgroundColor,
      color: textColor,
      borderRadius: '50%',
    };
  
    return (
      <StyledDay {...otherProps} style={customStyle}/>
    );
  };
  
  return (
    <ThemeProvider theme={CustomTheme}>
      <div style={{ ...calendarStyles, display: 'flex', justifyContent: 'center' }}>
        <StatefulCalendar
          onChange={handleDateRangeChange}
          monthsShown={2}
          minDate={minDate}
          maxDate={maxDate}
          orientation={isMobileView ? ORIENTATION.vertical : ORIENTATION.horizontal}
          range
          overrides={{
            Day: {
              component: dayRenderer,
            },
            PrevButton: {
              style: { display: 'none' }
            },
            NextButton: {
              style: { display: 'none' }
            }
          }}
        />
      </div>
    </ThemeProvider>
  );
};

export default CalendarComponent;

