const priceConfig = {
    day: 150,
    premium: 1000,
    luxury: 1300,
    vip_discount: 75,
    novel_discount: 70,
    yuga_discount: 60,
    regular_discount: 0,
    plusOne: 250
};

export default priceConfig;

